<template>
  <div v-if="rcmdPosts.length">
    <div class="game-rcmd-title">
      精彩内容
    </div>
    <div
      v-for="(item, index) in rcmdPosts"
      :key="index"
    >
      <div
        @click="handleJump(item)"
        class="game-rcmd-item-container"
      >
        <div class="game-rcmd-item-title-wrapper">
          <div class="game-rcmd-item-title">
            {{ item.title }}
          </div>
          <div>
            <span
              class="game-rcmd-item-subtitle"
            >
              <span>{{ groupName }}</span>
              <span class="dot">·</span>
              <span>{{ item.readCnt }}浏览</span>
            </span>
          </div>
        </div>
        <div class="game-rcmd-item-pic-wrapper">
          <img
            :src="item.pics.info[0].url"
            class="game-rcmd-item-pic"
            alt="picture"
          >
        </div>
      </div>
      <div class="game-rcmd-item-line" />
    </div>
  </div>
</template>

<script>
import { openView } from '@/jsbridge';
import { checkEnv } from '@/util/browser-env';
import launchApp from '@/util/launchApp';
import reportApi from '@/api/report';

export default {
  name: 'PostRcmd',
  props: {
    rcmdPosts: {
      type: Array,
      default() {
        return '';
      },
    },
    groupName: {
      type: String,
      default() {
        return '';
      },
    },
    gameCode: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      env: checkEnv(),
      rcmdItems: [],
      chatGroupInfo: null,
    };
  },
  mounted() {
    reportApi({
      pageid: '2902',
      moduleid: 98,
      eventtype: 2,
      extendid: 1,
      gameCode: this.gameCode,
    }, '新游介绍详情页-精彩内容曝光');
  },
  methods: {
    handleJump(item) {
      reportApi({
        pageid: '2902',
        moduleid: 98,
        eventtype: 3,
        extendid: 1,
        gameCode: this.gameCode,
      }, '新游介绍详情页-精彩内容点击');
      if (item.isChat) {
        // 聊天入口
        if (this.env.isApp) {
          openView({
            options: item.url,
          });
        } else {
          launchApp({
            scheme: item.url,
          });
        }
        return;
      }
      // 帖子跳转
      window.location.href = `https://mtest.gameplus.qq.com/community/post-detail?postId=${item.id}&_gcwv=2`;
    },
  },
};
</script>

<style lang="scss" src="./scss/index.scss">

</style>
